.form {
  label.form-label {
    font-weight: 500;
  }

  .required {
    label {
      position: relative;

      &::after {
        display: block;
        content: "*";
        color: $red;
        font-weight: bold;
        position: absolute;
        top: -3px;
        right: -8px;
      }
    }
  }

  .card-radio {
    > div > div {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(sm) {
        justify-content: space-between;
        flex-direction: row;
      }
    }

    .form-check {
      padding-left: 0;
      width: 100%;

      @include media-breakpoint-up(sm) {
        width: 47.8%;
      }

      label.form-check-label {
        height: 115px;
        width: 100%;
        margin: auto;
        border-radius: $input-border-radius;
        position: relative;
        transition: 0.5s;
        color: $input-color;
        background-color: $input-bg;
        background-clip: padding-box;
        border: $input-border-width solid $input-border-color;
        cursor: pointer;

        &::after {
          display: none;
        }

        > span {
          font-size: 1rem;
          font-weight: 500;
          position: absolute;
          top: 66%;
          display: block;
          width: 100%;
          text-align: center;

          // transform: translate(-50%, 80%);
        }

        &:hover,
        &:focus {
          border-color: $primary;

          > span,
          > i {
            color: $primary;
          }
        }
      }

      .bi {
        font-size: 50px;
        position: absolute;
        top: 58%;
        left: 50%;
        transform: translate(-50%, -80%);

        &.bi-id-applicant-0::before {
          content: "\f4da";
        }

        &.bi-id-applicant-1::before {
          content: "\f1dd";
        }

        &.bi-id-permit-type-0::before {
          content: "\f584";
          font-size: 40px;
          vertical-align: 0;
          color: $gray-700;
        }

        &.bi-id-permit-type-1::before {
          content: "\f584";
          font-size: 40px;
          vertical-align: 0;
          color: $gray-700;
        }
      }

      input[type="radio"] {
        appearance: none;
        visibility: hidden;

        &.is-invalid + label {
          border-color: $red !important;
        }

        &:checked + label.form-check-label {
          background-color: $primary;
          color: $white;
          border-color: $primary;

          &:hover,
          &:focus {
            > span,
            > i {
              color: $white;
            }
          }
        }
      }

      &.option-id-permit-type-0 {
        label {
          height: 115px;
          width: 100%;
          margin: auto;
          border-radius: $input-border-radius;
          position: relative;
          transition: 0.5s;
          color: $input-color;
          background-color: #ffe7b3;
          background-clip: padding-box;
          border: 1px solid #ffe7b3;
          cursor: pointer;

          &:hover,
          &:focus {
            border-color: #f8d100;

            > span,
            > i {
              color: #443f29 !important;
            }
          }
        }

        input[type="radio"]:checked + label.form-check-label {
          background-color: #f8d100;
          color: #443f29;
          border-color: #f8d100;

          .bi.bi-id-permit-type-0::before {
            content: "\f271";
            font-size: 50px;
            vertical-align: -0.1em;
          }
        }
      }

      &.option-id-permit-type-1 {
        label {
          height: 115px;
          width: 100%;
          margin: auto;
          border-radius: $input-border-radius;
          position: relative;
          transition: 0.5s;
          color: $input-color;
          background-color: #ffcbd5;
          background-clip: padding-box;
          border: 1px solid #ffcbd5;
          cursor: pointer;

          &:hover,
          &:focus {
            border-color: #c50a31;

            > span,
            > i {
              color: #280a10 !important;
            }
          }
        }

        input[type="radio"]:checked + label.form-check-label {
          background-color: #c50a31;
          color: #fff;
          border-color: #c50a31;

          &:hover,
          &:focus {
            > span,
            > i {
              color: #fff !important;
            }
          }

          .bi.bi-id-permit-type-1::before {
            content: "\f271";
            font-size: 50px;
            vertical-align: -0.1em;
            color: #fff;
          }
        }
      }
    }
  }
}
