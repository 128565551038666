// If you comment out code below, bootstrap will use red as primary color
// and btn-primary will become red
$body-bg: #eee;
$city-list-hover-bg: #f9f4da;
$gray-550: #979ea6 !default;
$card-spacer-y: 1.2rem;
$card-spacer-x: 1.5rem;
$green: #5faf50;
$success: $green;
$yellow: #e6aa55;
$warning: $yellow;
$red: #b81111;

// @import "~bootstrap/scss/bootstrap.scss";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";

// @import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";

// @import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";

// @import "~bootstrap/scss/transitions";
// @import "~bootstrap/scss/dropdown";
// @import "~bootstrap/scss/button-group";
// @import "~bootstrap/scss/nav";
// @import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";

// @import "~bootstrap/scss/accordion";
// @import "~bootstrap/scss/breadcrumb";
// @import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";

// @import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";

// @import "~bootstrap/scss/toasts";
// @import "~bootstrap/scss/modal";
// @import "~bootstrap/scss/tooltip";
// @import "~bootstrap/scss/popover";
// @import "~bootstrap/scss/carousel";
// @import "~bootstrap/scss/spinners";
// @import "~bootstrap/scss/offcanvas";
// @import "~bootstrap/scss/placeholders";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";

// scss-docs-end import-stack

@import "components/logo";
@import "components/form";
@import "sites/public";
