.public-main-container {
  max-width: 42rem;
}

.lines-around {
  width: 50%;
  text-align: center;
  border-bottom: 1px solid $gray-400;
  line-height: 0.1em;
  margin-left: auto;
  margin-right: auto;

  span {
    background-color: $white;
    color: $gray-700;
    padding: 0 10px;
  }
}

.city-list {
  .list-group-item {
    padding: 0;
    position: relative;

    &.city-detail-page {
      background-color: $card-cap-bg;
    }

    .city-item {
      padding: $card-spacer-y $card-spacer-x;
      display: flex;
      color: $gray-800;
      text-decoration: none;
      align-items: center;

      img {
        margin-right: 20px;
      }

      div {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h2 {
          padding: 0;
          margin: 0;
        }
      }
    }

    a.city-item {
      &:hover,
      &:focus {
        background-color: $city-list-hover-bg;
      }
    }
  }

  .back-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -1px;
    bottom: 0;
    left: -40px;
    width: 40px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    transition: 0.3s all;

    span {
      display: none;
    }

    i::before {
      font-weight: bold !important;
      line-height: 1.4 !important;
      font-size: 1.4rem !important;
    }

    &:hover,
    &:focus {
      span {
        display: inline;
      }

      i {
        margin: 0 12px 0 0;
      }

      left: -114px;
      width: 114px;
    }
  }
}

.footer-info {
  font-size: 0.75rem;
  padding: 10px 0;
  text-align: center;
  color: $gray-550;

  a {
    // font-weight: bold;
    color: $gray-600;
  }
}

.no-top-left-radius {
  border-top-left-radius: 0;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.font-bold {
  font-weight: bold;
}
