.public-site-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    max-width: 50px;
    height: auto;
    margin-right: 15px;
  }

  @include media-breakpoint-up(md) {
    flex-direction: column;
    align-items: center;

    img {
      width: 100%;
      max-width: 100px;
      height: auto;
      margin-bottom: 15px;
      margin-right: 0;
    }
  }
}
